import React, { useContext, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AppContext from '../Contexts/AppContext'
import './Work.css'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Fade,
  Grid,
  Typography
} from '@mui/material'
import { VerticalHeader } from '../VerticalHeader/VerticalHeader'

const Work = () => {
  const { workId } = useParams()
  const { works = [] } = useContext(AppContext)
  const pictures = works[workId] ? works[workId].work : []
  const name = works[workId]?.name
  const nameFontOffset = works[workId]?.nameFontOffset
  const descriptionForAllWorks = works[workId]?.description
  console.log(nameFontOffset)

  const [
    readDescriptionForPirctureId,
    setReadDescriptionForPirctureId
  ] = useState(undefined)

  const pdf = works[workId]?.pdf

  // const changeHeightOfSlideShowContainer = () => {
  //     const clientHeight = document.getElementById(pdf?.location + 0)?.clientHeight;
  //     const slideContainer = document.getElementById('Work-slide-test');
  //     if (slideContainer) {
  //         slideContainer.style.height = (clientHeight || 0) + 'px';
  //     }
  // };

  // changeHeightOfSlideShowContainer();

  return (
    <div className='Work-container'>
      {pictures.map(({ src, description }, index) => (
        <div
          key={src + index}
          onClick={() =>
            setReadDescriptionForPirctureId(prevState =>
              prevState === index ? undefined : index
            )
          }
        >
          <div>
            <img
              className={
                readDescriptionForPirctureId === index ? 'Work-hidden' : ''
              }
              src={src}
              alt={description}
            />
            <p
              className={
                readDescriptionForPirctureId !== index ? 'Work-hidden' : ''
              }
            >
              {description}
            </p>
          </div>
        </div>
      ))}
      {/* {pdf && <embed src={'../' + pdf}  width="800px" height="2100px" />} */}
      <WorkCover
        name={name}
        pdf={pdf}
        description={descriptionForAllWorks}
        nameFontOffset={nameFontOffset}
      />
    </div>
  )
}

const WorkCover = ({
  name,
  description = '',
  pdf = [],
  nameFontOffset = 1
}) => {
  const [fadeIn, setFadeIn] = useState(!pdf)
  console.log(name)
  return (
    <VerticalHeader name={name} offset={nameFontOffset}>
      <Fade in={fadeIn}>
        <Grid
          container
          spacing={5}
          sx={{ marginTop: '3rem' }}
          textAlign={{ md: 'initial' }}
          marginLeft={{ md: '2rem' }}
        >
          {(Array.isArray(description) ? description : [description]).map(
            paragraphDesc => (
              <React.Fragment key={paragraphDesc}>
                <Grid item xs={0} md={1} />
                <Grid item xs={12} md={11}>
                  <Typography variant='body1'>{paragraphDesc}</Typography>
                </Grid>
              </React.Fragment>
            )
          )}

          {pdf.map((pdfElement, index) => (
            <Grid key={pdfElement.title} item container spacing={5}>
              <Grid item xs={0} md={1} />
              <Grid item container xs={12} md={6}>
                <Grid item xs={12}>
                  <Typography variant='h3' marginTop={2}>
                    {pdfElement.title}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent={'flex-start'}
                  marginTop={4}
                  alignContent={'flex-start'}
                  spacing={3}
                >
                  {(Array.isArray(pdfElement.description)
                    ? pdfElement.description
                    : [pdfElement.description]
                  ).map(pdfElementDescParagraph => (
                    <Grid
                      key={pdfElementDescParagraph}
                      item
                      xs={12}
                      textAlign={{ md: 'left' }}
                    >
                      {/* TODO FIX MARGIN HERE */}
                      <Typography variant='body1'>
                        {pdfElementDescParagraph}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} md={5}>
                {pdfElement.singleSrc ? (
                  <a href={pdfElement.singleSrc}>
                    <img
                      onLoad={() => setFadeIn(true)}
                      onError={() => setFadeIn(true)}
                      src={pdfElement.singleSrc}
                      width={250}
                      alt={name}
                    />
                  </a>
                ) : (
                  <ViewPresentationCard
                    path={document.location.pathname + '/presentation/' + index}
                    onLoad={() => setFadeIn(true)}
                    onError={() => setFadeIn(true)}
                    imgSrc={`/${pdfElement.location}/${pdfElement.location}-01.webp`}
                    imgAlt={'Presentation of ' + name}
                  />
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Fade>
    </VerticalHeader>
  )
}

export const ViewPresentationCard = ({
  path,
  onLoad = () => undefined,
  onError = () => undefined,
  imgSrc,
  imgAlt
}) => {
  return (
    <Box maxWidth={350} sx={{ mx: { xs: 'auto', lg: 0 } }} marginTop={5}>
      <Link to={path}>
        <Card
          sx={{
            maxWidth: 345,
            marginLeft: '0.5rem',
            marginRight: '0.5rem'
          }}
        >
          <CardActionArea>
            <CardMedia
              onLoad={onLoad}
              onError={onError}
              component='img'
              className='WorkCover-image'
              image={imgSrc}
              alt={imgAlt}
            />
            <CardContent>
              <Typography variant='h5' component='div'>
                View presentation
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </Box>
  )
}

export default Work
