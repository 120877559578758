import { Grid, Typography } from '@mui/material'

export const VerticalHeader = ({ name = '', children, offset = 1 }) => {
  return (
    <Grid container spacing={1} paddingX={{ xs: 3, md: 0 }}>
      <Grid
        item
        container
        md={1}
        height={'80vh'}
        sx={{ display: { xs: 'none', md: 'flex' } }}
      >
        <Grid
          item
          container
          md={4}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <Typography
            variant='h1'
            fontSize={{
              md: (offset * window.innerHeight) / 140 + 'rem !important'
            }}
            fontFamily={'MAK-bold'}
            sx={{
              transform: 'rotate(270deg)',
              height: '5rem',
              position: 'fixed',
              marginTop: window.innerHeight / 40 + 'rem',
              marginLeft: 3000 / window.innerHeight + 'rem',
              whiteSpace: 'nowrap'
            }}
          >
            {name.toUpperCase()}
          </Typography>
        </Grid>
        <Grid item xs={8}></Grid>
      </Grid>
      <Grid item container md={11} xs={12} spacing={2}>
        {children}
      </Grid>
    </Grid>
  )
}
