import { useContext, useEffect, useState } from 'react'
import './About.css'
import AppContext from '../Contexts/AppContext'
import { Fade, Grid, IconButton, Typography } from '@mui/material'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import InstagramIcon from '@mui/icons-material/Instagram'
import EmailIcon from '@mui/icons-material/Email'
import { makeStyles } from '@mui/styles'
import mainPic from './main.webp'
import profilePic from './profile.webp'

const useStyles = makeStyles({
  root: {
    maxWidth: 310,
    transition: 'transform 0.5s ease-in-out'
  },
  cardHovered: {
    transform: 'scale3d(1.5, 1.5, 1)'
  }
})

const About = () => {
  const { about = {} } = useContext(AppContext)
  const [picLoaded, setPicLoaded] = useState(false)
  const classes = useStyles()
  const [state, setState] = useState({
    id: -1,
    raised: false,
    shadow: 1
  })

  const socialMedia = {
    LinkedIn: LinkedInIcon,
    Instagram: InstagramIcon,
    Email: EmailIcon
  }

  function oppdaterProfilepicPosition () {
    if (window.innerWidth >= 900) {
      const maxChangeInYDir =
        -Math.min(Math.pow(window.innerHeight, 1.1), 1.1 * window.innerHeight) *
        (window.innerWidth / window.innerHeight) *
        0.9
      const changeInYDir = maxChangeInYDir * getScrollPercent()
      const styleToProfilePic = document.getElementById('About-profilePic')
        ?.style
      if (styleToProfilePic) {
        styleToProfilePic.transform = `translateY(${changeInYDir}px) translateZ(0px)`
      }
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', oppdaterProfilepicPosition)
    return () => {
      document.removeEventListener('scroll', oppdaterProfilepicPosition)
    }
  }, [])

  return (
    <Fade in={picLoaded}>
      <div className='About-container'>
        <div>
          <Grid container justifyContent={'center'}>
            <Grid
              item
              container
              spacing={3}
              id='About-profilePic'
              className={'About-profilepic'}
            >
              {/* FIX IMG SIZE FULL WIDTH */}
              <Grid item xs={12}>
                <img
                  onLoad={() => setPicLoaded(true)}
                  width={'100%'}
                  src={mainPic}
                  alt='Raphaella Collado Cahuas'
                />
              </Grid>
              <Grid item xs={12}>
                <img
                  width={'100%'}
                  src={profilePic}
                  alt='Raphaella Collado Cahuas'
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className='About-text'>
          <div>
            <Grid
              container
              spacing={4}
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              marginBottom={10}
            >
              <Grid item>
                <Typography variant='h2'>Hello!</Typography>
              </Grid>
              <Grid item>
                <Typography variant='h2'>Hola!</Typography>
              </Grid>
            </Grid>
            {about.main?.map(({ titles = [], body = [] }, index) => (
              <Grid key={index} container marginBottom={10}>
                <Grid marginBottom={titles.length > 0 ? 2 : 0}>
                  {titles.map(title => (
                    <div key={title}>
                      <Typography
                        key={title}
                        variant='h6'
                        component='h3'
                        gutterBottom
                      >
                        {title}
                      </Typography>
                    </div>
                  ))}
                </Grid>

                {body.map(bodyText => (
                  <Typography
                    key={bodyText}
                    variant='body1'
                    component='p'
                    gutterBottom
                  >
                    {bodyText}
                  </Typography>
                ))}
              </Grid>
            ))}

            <Typography
              variant='h4'
              component='h2'
              gutterBottom
              className='About-disiplines'
            >
              Skills / Habilidades
            </Typography>

            <Grid container>
              <Grid item container xs={6} spacing={4}>
                {(about.disiplines || [])[0]?.map(disiplines => (
                  <Grid key={disiplines} item xs={12}>
                    <Typography variant='body1' component='span'>
                      {disiplines}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid item container xs={6} spacing={4}>
                {(about.disiplines || [])[1]?.map(disiplines => (
                  <Grid key={disiplines} item xs={12}>
                    <Typography
                      key={disiplines}
                      variant='body1'
                      component='span'
                    >
                      {disiplines}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Typography
              variant='h4'
              component='h2'
              gutterBottom
              className='About-contact'
            >
              Contact
            </Typography>
            <div className='About-contact-list'>
              {about.contact?.links?.map(({ text, url, at = '' }, index) => {
                const Icon = socialMedia[text]
                if (Icon) {
                  return (
                    <a href={url} key={text}>
                      <IconButton
                        key={index}
                        className={classes.root}
                        classes={{
                          root:
                            state.id === index && state.raised
                              ? classes.cardHovered
                              : ''
                        }}
                        onMouseOver={() =>
                          setState({ id: index, raised: true, shadow: 3 })
                        }
                        onMouseOut={() =>
                          setState(val =>
                            val.id === index
                              ? { raised: false, shadow: 1 }
                              : val
                          )
                        }
                        raised={state.id === index && state.raised ? 1 : 0}
                        zdepth={state.id === index ? state.shadow : 1}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                        disableRipple
                      >
                        <Icon fontSize='large' />
                        <Typography variant='caption'>{at}</Typography>
                      </IconButton>
                    </a>
                  )
                } else {
                  return undefined
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </Fade>
  )
}

function getScrollPercent () {
  var h = document.documentElement,
    b = document.body,
    st = 'scrollTop',
    sh = 'scrollHeight'
  return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)
}

export default About
