// import gsap from 'gsap/all';
import { Fade, Grid, Typography } from '@mui/material'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import AppContext from '../Contexts/AppContext'
import './Works.css'

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary
// }))

const Works = () => {
  const { works = [] } = useContext(AppContext)

  return (
    <Fade in>
      <Grid
        container
        direction={'column'}
        justifyContent={'flex-start'}
        alignItems={'stretch'}
        marginTop={'2rem'}
      >
        {works
          .map(({ name, fullName, picture, href }, index) => ({
            workRender: (
              <Grid key={fullName || name} item>
                <Link
                  to={href ? href : `work/` + index}
                  className='Works-each-work-name'
                >
                  <Grid
                    container
                    overflow={'hidden'}
                    alignItems={'left'}
                    paddingRight={'auto'}
                  >
                    <Typography
                      width={'100%'}
                      className='Works-each-work-name'
                      variant='h2'
                      padding={'1.5rem 2rem 1.5rem 2rem'}
                      color={'#000000'}
                      textAlign={'left'}
                    >
                      {fullName || name}
                    </Typography>
                  </Grid>
                </Link>
              </Grid>
            ),
            sortValue: name
          }))
          .sort((a, b) => ('' + a.sortValue).localeCompare(b.sortValue))
          .map(sortedItems => sortedItems.workRender)}
      </Grid>
    </Fade>
  )

  // return <Fade in={true}>
  //     <div className="Works-container" ref={boxRef}>
  //         {works.map(({ name, picture }, index) =>
  //             <Link to={'/work/' + index} key={name}>
  //                 <div className='Works-each-work-container'>
  //                     <Typography variant='h1' component='h2'>{index + 1}</Typography>
  //                     <Typography variant='h1' component='h2'>{name}</Typography>
  //                     <img src={picture} alt={name} />
  //                 </div>
  //             </Link>
  //         )}
  //     </div>
  // </Fade>
}

export default Works
