import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { Grid, Tab, Tabs } from '@mui/material'
import { getLanguage } from '../App'

const ResponsiveAppBar = () => {
  return (
    <AppBar position='fixed'>
      <Container maxWidth='xl'>
        <Grid container>
          <Grid item xs={12} md={6} marginTop={'1rem'} marginBottom={{md: '1rem'}}>
            <Typography
              variant='h5'
              fontFamily={'MAK-bold'}
              noWrap
              component='h1'
              sx={{
                mr: 2,
                display: 'flex',
                alignItems: 'flex-end',
                flexDirection: 'row'
              }}
            >
              Raphaella Collado Cahuas
            </Typography>
          </Grid>

          {/* <Grid xs={1} /> */}
          <Grid item container xs={12} md={6} justifyContent={'flex-end'}>
            <Tabs
              sx={{
                justifyContent: 'flex-end',
                marginTop: 'auto',
                alignSelf: 'flex-end',
                fontFamily: 'MAK-bold'
              }}
              value={
                useRouteMatch([
                  { path: '/:language', exact: true },
                  { path: '/:language/about' }
                ])?.pattern?.path || '/:language'
              }
              textColor='secondary'
              indicatorColor='secondary'
            >
              <Tab
                key={1}
                sx={{ color: 'white' }}
                label='Work'
                value='/:language'
                to={getLanguage()}
                component={Link}
              />
              <Tab
                key={2}
                sx={{ color: 'white' }}
                label='About'
                value='/:language/about'
                to={getLanguage() + '/about'}
                component={Link}
              />
            </Tabs>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  )
}

function useRouteMatch (patterns) {
  const { pathname } = useLocation()

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i]
    const possibleMatch = matchPath(pattern, pathname)
    if (possibleMatch !== null) {
      return possibleMatch
    }
  }

  return null
}

export default ResponsiveAppBar
