import './App.css'
import { BrowserRouter, NavLink, Route, Routes, Navigate } from 'react-router-dom'
import Works from './Works/Works'
import About from './About/About'
import Work from './Work/Work'
import AppContext from './Contexts/AppContext'
import React, { useEffect, useMemo, useState } from 'react'
import { PresentationWrapper } from './Work/Presentation'
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material'
import ResponsiveAppBar from './Header/AppBar'
import Production from './Production/Production'
import ProductionPresentation from './Production/ProductionPresentation'
import Styling from './Styling/Styling'

const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, ...other } = props
  // Map href (MUI) -> to (react-router)
  return <NavLink ref={ref} to={href} {...other} />
})

export const allFonts = [
  'MAK',
  'TildaSans',
  'MAK-bold'
]

function App () {
  const [works, setWorks] = useState(undefined)
  const [about, setAbout] = useState(undefined)

  const [headerFont, setHeaderFont] = useState(allFonts[0])
  const [bodyFont, setBodyFont] = useState(allFonts[1])
  // console.log(headerFont);

  const currentTheme = useMemo(() => {
    const tempTheme = createTheme({
      typography: {
        h1: {
          fontFamily: headerFont
        },
        h2: {
          fontFamily: headerFont
        },
        h3: {
          fontFamily: headerFont
        },
        h4: {
          fontFamily: headerFont
        },
        h5: {
          fontFamily: headerFont
        },
        fontFamily: [bodyFont].join(',')
      },
      palette: {
        primary: {
          main: '#39989D',
          light: '#68A0B0',
          dark: '#2F4C5B'
        },
        background: '#F5F5F5',
        secondary: {
          main: '#EAB6A5',
          light: '#EECEC5'
        }
      },
      components: {
        MuiLink: {
          defaultProps: {
            component: LinkBehavior
          }
        },
        MuiButtonBase: {
          defaultProps: {
            LinkComponent: LinkBehavior
          }
        }
      }
    });
    return responsiveFontSizes(tempTheme);
  }, [headerFont, bodyFont]);
  
  useEffect(() => {
    fetch(`/works-${getLanguage()}.json`)
      .then(response => {
        return response.json()
      })
      .then(json => {
        setWorks(json)
      })
      .catch(err => console.error(err))
  }, [])
  useEffect(() => {
    fetch(`/about-${getLanguage()}.json`)
      .then(response => {
        return response.json()
      })
      .then(json => {
        setAbout(json)
      })
      .catch(err => console.error(err))
  }, [])
  return (
    <BrowserRouter>
      <ThemeProvider theme={currentTheme}>
        <AppContext.Provider value={{ works, about }}>
          <div className='App'>
            <ResponsiveAppBar />
            <div className='About-body' style={{paddingTop: '3rem'}}>
              <Routes>
                <Route exact path="/" element={<Navigate replace to="/en" />} />
                {/* <BrowserRouter basename='/:language' > */}
                <Route path='/:language' element={<Works />} />
                <Route path='/:language/about' element={<About />} />
                <Route path='/:language/work/:workId' element={<Work />} />
                <Route
                  path='/:language/work/:workId/presentation/:presentationId'
                  element={<PresentationWrapper />}
                />
                <Route path='/:language/production' element={<Production />} />
                <Route path='/:language/production/presentation/:presentationId' element={<ProductionPresentation />} />
                <Route path='/:language/work/2' element={<Styling />} />
                {/* </BrowserRouter> */}
              </Routes>
            </div>
          </div>
        </AppContext.Provider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export function getLanguage() {
  return window.location.pathname.split('/')[1] || 'en'; 
}

export default App
