import { useParams } from 'react-router-dom';
import { Presentation } from '../Work/Presentation'

const ProductionPresentation = () => {
  const { presentationId = 0 } = useParams();
  const productionData = [
    {
      location: 'Copy of GUCCI-GOD COMPLEX _page-00',
      title: 'Gucci - God Complex',
      description: 'This is my Gucci - God Complex project.',
      size: 28
    },
    {
      location: 'Prada Christmas 00',
      title: 'Prada Christmas',
      description: 'This is my Prada Christmas project.',
      size: 14
    },
    {
      location: 'Prada Christmas 2-',
      title: 'Prada Christmas 2',
      description: 'This is my Prada Christmas project.',
      size: 11
    }
  ]
  return (
    <Presentation
      bindChar=""
      pdf={productionData[presentationId]}
    />
  )
}

export default ProductionPresentation;
