import { Grid, ImageList, ImageListItem, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import pic0 from './Copy of GUCCI_0.webp'
import pic0Small from './small/Copy of GUCCI_0.webp'
import pic1 from './Copy of GUCCI_1.webp'
import pic1Small from './small/Copy of GUCCI_1.webp'
import pic3 from './Copy of GUCCI_3.webp'
import pic3Small from './small/Copy of GUCCI_3.webp'
import pic4 from './Copy of GUCCI_4.webp'
import pic4Small from './small/Copy of GUCCI_4.webp'
import pic6 from './Copy of GUCCI_6.webp'
import pic6Small from './small/Copy of GUCCI_6.webp'
import pic7 from './Copy of GUCCI_7.webp'
import pic7Small from './small/Copy of GUCCI_7.webp'
import pic8 from './Copy of GUCCI_8.webp'
import pic8Small from './small/Copy of GUCCI_8.webp'
import pic9 from './Copy of GUCCI_9.webp'
import pic9Small from './small/Copy of GUCCI_9.webp'
import pic10 from './Copy of GUCCI_10.webp'
import pic10Small from './small/Copy of GUCCI_10.webp'
import { VerticalHeader } from '../VerticalHeader/VerticalHeader'

const useStyles = makeStyles({
  a: {
    display: 'block',
    height: '100%'
  },
  img: {
    objectFit: 'cover',
    width: '100%',
    height: '100%'
  }
})

const Production = () => {
  const itemDataGodComplex = [
    {
      src:
        '/Copy of GUCCI-GOD COMPLEX _page-00/Copy of GUCCI-GOD COMPLEX _page-0001.webp',
      title: 'God Complex Presentation',
      href: '/presentation/0',
      rows: 3,
      cols: 3
    },
    {
      src: pic1,
      srcSet: pic1Small,
      title: 'God Complex Picture 1',
      rows: 2,
      cols: 1
    },
    {
      src: pic3,
      srcSet: pic3Small,
      title: 'God Complex Picture 3',
      rows: 4,
      cols: 1
    },
    {
      src: pic4,
      srcSet: pic4Small,
      title: 'God Complex Picture 4',
      rows: 2,
      cols: 2
    },
    {
      src: pic6,
      srcSet: pic6Small,
      title: 'God Complex Picture 6',
      rows: 2,
      cols: 1
    },
    {
      src: pic7,
      srcSet: pic7Small,
      title: 'God Complex Picture 7',
      rows: 2,
      cols: 1
    },
    {
      src: pic8,
      srcSet: pic8Small,
      title: 'God Complex Picture 8',
      rows: 2,
      cols: 2
    },
    {
      src: pic9,
      srcSet: pic9Small,
      title: 'God Complex Picture 9',
      rows: 2,
      cols: 1
    },
    {
      src: pic10,
      srcSet: pic10Small,
      title: 'God Complex Picture 10',
      rows: 2,
      cols: 1
    },
    {
      src: pic0,
      srcSet: pic0Small,
      title: 'God Complex Picture 27',
      rows: 2,
      cols: 1
    }
  ]
  const itemDataPradaChristmas = [
    {
      src: '/Prada Christmas 00/Prada Christmas 0001.webp',
      title: 'Prada Christmas Presentation 1',
      href: '/presentation/1',
      rows: 2,
      cols: 3
    },
    {
      src: '/Prada Christmas 00/Prada Christmas 0002.webp',
      title: 'Prada Christmas Presentation 1',
      rows: 1,
      cols: 1
    },
    {
      src: '/Prada Christmas 00/Prada Christmas 0003.webp',
      title: 'Prada Christmas Presentation 1',
      rows: 1,
      cols: 1
    },
    {
      src: '/Prada Christmas 2-/Prada Christmas 2-02.webp',
      title: 'Prada Christmas Presentation 2',
      rows: 1,
      cols: 1
    },
    {
      src: '/Prada Christmas 2-/Prada Christmas 2-01.webp',
      title: 'Prada Christmas Presentation 2',
      href: '/presentation/2',
      rows: 2,
      cols: 3
    },
    {
      src: '/Prada Christmas 2-/Prada Christmas 2-03.webp',
      title: 'Prada Christmas Presentation 2',
      rows: 1,
      cols: 1
    }
  ]
  const classes = useStyles()

  return (
    <VerticalHeader name={'PRODUCTION'}>
      <Grid
        item
        container
        direction={'row'}
        xs={12}
        marginTop={'4rem'}
        marginLeft={{ md: '3rem' }}
        textAlign={'initial'}
      >
        <Grid item xs={12}>
          <Typography variant='body1'>
            In this page you will observe my process and results in projects in
            which the focus was production.
          </Typography>
        </Grid>
        <Grid item xs={12} marginTop={5}>
          <Typography variant='body1'>
            En esta sección podrás observar mi proceso y resultado en proyectos
            donde el foco era la producción de moda.
          </Typography>
        </Grid>
      </Grid>
      {/* GOD COMPLEX */}
      <Grid
        item
        container
        md={4}
        sx={{
          marginLeft: { md: '3rem' },
          marginTop: '2rem',
          height: { md: '80vh' }
        }}
        justifyContent={'space-between'}
        textAlign={'initial'}
      >
        <Grid item xs={12}>
          <Typography variant='h2'>
            GOD COMPLEX
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: { md: '20vh' } }}>
          <Typography variant='body1'>
            
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        md={7}
        alignContent='center'
        justifyContent={'center'}
      >
        <ImageList
          sx={{ width: { xl: '60vw' }, height: 1266 }}
          variant='quilted'
          cols={4}
          rowHeight={125}
        >
          {itemDataGodComplex.map(item => (
            <ImageListItem
              key={item.src}
              cols={item.cols || 1}
              rows={item.rows || 1}
            >
              <a
                href={
                  item.href
                    ? window.location.href + (item.href || '')
                    : item.src
                }
                className={classes.a}
              >
                <img
                  src={item.srcSet ? item.srcSet : item.src}
                  srcSet={item.srcSet ? `${item.srcSet}, ${item.src}` : undefined}
                  alt={item.title}
                  loading='lazy'
                  className={classes.img}
                />
              </a>
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>
      {/* PRADA CHRISTMAS */}
      <Grid
        item
        container
        md={4}
        sx={{
          marginLeft: { md: '3rem' },
          marginTop: '2rem',
          height: { md: '80vh' }
        }}
        justifyContent={'space-between'}
        textAlign={'initial'}
      >
        <Grid item xs={12}>
          <Typography variant='h3'>
            PRADA CHRISTMAS
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: { md: '20vh' } }}>
          <Typography variant='body1'>
            
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        md={7}
        alignContent='center'
        justifyContent={'center'}
      >
        <ImageList
          sx={{ width: { xl: '60vw' }, height: 734 }}
          variant='quilted'
          cols={4}
          rowHeight={180}
        >
          {itemDataPradaChristmas.map(item => (
            <ImageListItem
              key={item.src}
              cols={item.cols || 1}
              rows={item.rows || 1}
            >
              <a
                href={
                  item.href
                    ? window.location.href + (item.href || '')
                    : item.src
                }
                className={classes.a}
              >
                <img
                  src={item.srcSet ? item.srcSet : item.src}
                  srcSet={item.srcSet ? `${item.srcSet}, ${item.src}` : undefined}
                  alt={item.title}
                  loading='lazy'
                  className={classes.img}
                />
              </a>
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>
    </VerticalHeader>
  )
}

export default Production
