import { useContext, useState } from 'react';

import { Box, CircularProgress, Fab, Fade, useScrollTrigger, Zoom } from "@mui/material";
import './Work.css';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useParams } from 'react-router-dom';
import AppContext from '../Contexts/AppContext';

export const Presentation = ({pdf, bindChar = '-'}) => {
    const [numPicLoaded, setNumPicLoaded] = useState(0);

    return <div>
        {numPicLoaded !== (pdf?.size || Number.MAX_SAFE_INTEGER)
            && <Box marginTop={'1rem'}><CircularProgress /></Box>
        }
        <Fade in={numPicLoaded === (pdf?.size || Number.MAX_SAFE_INTEGER)}>
            <div>
                <div className='Presentation-image-container'>
                    {[...Array(pdf?.size || 0).keys()].map(id => {
                        return <img
                            onLoad={() => setNumPicLoaded(val => val + 1)}
                            onError={() => setNumPicLoaded(val => val + 1)}
                            id={pdf.location + id}
                            key={id}
                            className='Work-slide'
                            src={getSrcForSlideInProject(pdf.location, id, bindChar)}
                            alt={'Slide number ' + id}
                            loading={'test'}
                        />
                    })}
                </div>

                <ScrollTop>
                    <Fab color="#3C6777" size="small" aria-label="scroll back to top">
                        <KeyboardArrowUpIcon />
                    </Fab>
                </ScrollTop>
            </div>
        </Fade>
    </div>;
};

export const PresentationWrapper = () => {
    const { workId, presentationId } = useParams();
    const { works = [] } = useContext(AppContext);
    const pdf = works[workId]?.pdf[presentationId];

    return <Presentation pdf={pdf} />
}

export function getSrcForSlideInProject(location, id, bindChar = '-') {
    return `/${location}/${location}${bindChar}${(id < 9 ? '0' : '') + (id + 1)}.webp`
}

function ScrollTop({ children }) {
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (
            (event.target).ownerDocument || document
        ).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    return (
        <Zoom in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                {children}
            </Box>
        </Zoom>
    );
}