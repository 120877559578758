import { Grid, ImageList, ImageListItem, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { VerticalHeader } from '../VerticalHeader/VerticalHeader'
import bag from './aef.webp'
import bagSmall from './small/aef.webp'
import crouchA from './asdf.webp'
import crouchASmall from './small/asdf.webp'
import sunDownA from './beauty.webp'
import sunDownASmall from './small/beauty.webp'
import kneeA from './esta.webp'
import kneeASmall from './small/esta.webp'
import kneeGlassA from './esti.webp'
import kneeGlassASmall from './small/esti.webp'
import sleepA from './ijn.webp'
import sleepASmall from './small/ijn.webp'
import hugAD from './IMG_3119 copia.webp'
import hugADSmall from './small/IMG_3119 copia.webp'
import bwAD from './IMG_3147 copia.webp'
import bwADSmall from './small/IMG_3147 copia.webp'
import inGlassD from './IMG_3171.webp'
import inGlassDSmall from './small/IMG_3171.webp'
import inGlassA from './IMG_3177 copia.webp'
import inGlassASmall from './small/IMG_3177 copia.webp'
import lookOverD from './IMG_3184.webp'
import lookOverDSmall from './small/IMG_3184.webp'
import lookDownA from './IMG_3210.webp'
import lookDownASmall from './small/IMG_3210.webp'
import sleep2A from './lal.webp'
import sleep2ASmall from './small/lal.webp'
import doubleKneeHandsA from './s.webp'
import doubleKneeHandsASmall from './small/s.webp'
import something from './IMG_3153.webp'
import somethingSmall from './small/IMG_3153.webp'

const useStyles = makeStyles({
  a: {
    display: 'block',
    height: '100%'
  },
  img: {
    objectFit: 'cover',
    width: '100%',
    height: '100%'
  }
})

const Styling = () => {
  const itemDataGodComplex = [
    {
      src: '/Botanical Garden/Botanical Garden-01.webp',
      title: 'Botanical Garden Presentation',
      href: '/presentation/0',
      rows: 1,
      cols: 3
    },
    {
      src: crouchA,
      srcSet: crouchASmall,
      title: 'Botanical Garden Picture 1',
      rows: 1,
      cols: 1
    },
    {
      src: sleep2A,
      srcSet: sleep2ASmall,
      title: 'Botanical Garden Picture 2',
      rows: 2,
      cols: 1
    },
    {
      src: bag,
      srcSet: bagSmall,
      title: 'Botanical Garden Picture 3',
      rows: 1,
      cols: 1
    },
    {
      src: kneeA,
      srcSet: kneeASmall,
      title: 'Botanical Garden Picture 4',
      rows: 1,
      cols: 1
    },
    {
      src: sleepA,
      srcSet: sleepASmall,
      title: 'Botanical Garden Picture 5',
      rows: 1,
      cols: 1
    },
    {
      src: doubleKneeHandsA,
      srcSet: doubleKneeHandsASmall,
      title: 'Botanical Garden Picture 6',
      rows: 1,
      cols: 1
    },
    {
      src: sunDownA,
      srcSet: sunDownASmall,
      title: 'Botanical Garden Picture 7',
      rows: 1,
      cols: 1
    },
    {
      src: kneeGlassA,
      srcSet: kneeGlassASmall,
      title: 'Botanical Garden Picture 8',
      rows: 1,
      cols: 1
    }
  ]
  const itemDataPradaChristmas = [
    {
      src: '/Genderless Eyewear/Genderless Eyewear-01.webp',
      title: 'Genderless Eyewear Presentation 1',
      href: '/presentation/1',
      rows: 2,
      cols: 3
    },
    {
      src: hugAD,
      srcSet: hugADSmall,
      title: 'Genderless Eyewear Picture 3',
      rows: 1,
      cols: 1
    },
    {
      src: bwAD,
      srcSet: bwADSmall,
      title: 'Genderless Eyewear Picture 4',
      rows: 1,
      cols: 1
    },
    {
      src: inGlassD,
      srcSet: inGlassDSmall,
      title: 'Genderless Eyewear Picture 5',
      rows: 1,
      cols: 1
    },
    {
      src: inGlassA,
      srcSet: inGlassASmall,
      title: 'Genderless Eyewear Picture 6',
      rows: 1,
      cols: 1
    },
    {
      src: lookOverD,
      srcSet: lookOverDSmall,
      title: 'Genderless Eyewear Picture 7',
      rows: 2,
      cols: 1
    },
    {
      src: lookDownA,
      srcSet: lookDownASmall,
      title: 'Genderless Eyewear Picture 8',
      rows: 2,
      cols: 1
    },
    {
      src: something,
      srcSet: somethingSmall,
      title: 'Genderless Eyewear Picture 9',
      rows: 1,
      cols: 1
    }
  ]

  const classes = useStyles()

  return (
    <VerticalHeader name={'STYLING'}>
      <Grid
        item
        container
        direction={'row'}
        xs={12}
        marginTop={'4rem'}
        marginLeft={{ md: '3rem' }}
        textAlign={'initial'}
      >
        <Grid item xs={12}>
          <Typography variant='body1'>
            In this page you will observe my process and results in projects in
            which the focus was fashion styling.
          </Typography>
        </Grid>
        <Grid item xs={12} marginTop={5}>
          <Typography variant='body1'>
            En esta sección podrás observar mi proceso y resultados en proyectos
            donde el foco era el styling.
          </Typography>
        </Grid>
      </Grid>
      {/* GOD COMPLEX */}
      <Grid
        item
        container
        md={4}
        sx={{
          marginLeft: { md: '3rem' },
          marginTop: '2rem',
          height: { md: '80vh' }
        }}
        justifyContent={'space-between'}
        textAlign={'initial'}
      >
        <Grid item xs={12}>
          <Typography variant='h3'>BOTANICAL GARDEN</Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sx={{ marginBottom: { md: '20vh' } }}
          spacing={5}
        >
          <Grid item>
            <Typography variant='body1'>
              For this project we had to choose a luxury brand that we liked, in
              this case I chose Prada. Then we had to develop a concept that
              aligned with the brand and create a collection of images.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1'>
              Para este trabajo debíamos escoger una marca de lujo que nos
              gustara, en este caso elegí Prada. Luego teníamos que crear un
              concepto que se alinee con la marca y desarrollar una colección de
              imágenes.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        md={7}
        alignContent='center'
        justifyContent={'center'}
      >
        <ImageList
          sx={{ width: { xl: '60vw' }, height: 760 }}
          variant='quilted'
          cols={4}
          rowHeight={250}
        >
          {itemDataGodComplex.map(item => (
            <ImageListItem
              key={item.src}
              cols={item.cols || 1}
              rows={item.rows || 1}
            >
              <a
                href={
                  item.href
                    ? window.location.href + (item.href || '')
                    : item.src
                }
                className={classes.a}
              >
                <img
                  src={item.srcSet ? item.srcSet : item.src}
                  srcSet={item.srcSet ? `${item.srcSet}, ${item.src}` : undefined}
                  alt={item.title}
                  loading='lazy'
                  className={classes.img}
                />
              </a>
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>
      {/* ESCARLATA EYEWEAR */}
      <Grid
        item
        container
        md={4}
        sx={{
          marginLeft: { md: '3rem' },
          marginTop: '2rem',
          height: { md: '80vh' }
        }}
        justifyContent={'space-between'}
        textAlign={'initial'}
      >
        <Grid item xs={12}>
          <Typography variant='h3'>ESCARLATA EYEWEAR</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          sx={{ marginBottom: { md: '20vh' } }}
          spacing={5}
        >
          <Grid item>
            <Typography variant='body1'>
              For this project we chose a Peruvian brand, and they gave us the
              theme to work on. In this case the brand was Escarlata Eyewear and
              the concept: Genderless. Content was created for an alleged
              release.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1'>
              Para este trabajo escogimos una marca peruana, y nos dieron el
              tema a trabajar. En este caso la marca fue Escarlata Eyewear y el
              concepto Genderless. Se exreó contenido para un supuesto
              lanzamiento.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        md={7}
        alignContent='center'
        justifyContent={'center'}
      >
        <ImageList
          sx={{ width: { xl: '60vw' }, height: 740 }}
          variant='quilted'
          cols={4}
          rowHeight={180}
        >
          {itemDataPradaChristmas.map(item => (
            <ImageListItem
              key={item.src}
              cols={item.cols || 1}
              rows={item.rows || 1}
            >
              <a
                href={
                  item.href
                    ? window.location.href + (item.href || '')
                    : item.src
                }
                className={classes.a}
              >
                <img
                  src={item.srcSet ? item.srcSet : item.src}
                  srcSet={item.srcSet ? `${item.srcSet}, ${item.src}` : undefined}
                  alt={item.title}
                  loading='lazy'
                  className={classes.img}
                />
              </a>
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>
    </VerticalHeader>
  )
}

export default Styling
